<template>
    <div>
        <v-card>
            <v-card-title>Listado de Subcostos</v-card-title>
            <v-card-text>
                <botonera 
                    :botones="botones" 
                    alinear="right"
                    @crear="dialog = !dialog"
                    @cargar="upload = !upload"
                    @eliminar="deleteAll"
                ></botonera>
            </v-card-text>
        </v-card>

        <datatable 
            :cabeceras="subcostosListHeaders" 
            :items="subcostosListBody" 
            titulo="Listado de Subcostos" 
            label="Escriba para buscar Subcostos" 
            icono="list" 
            color_icono="azul" 
            :tachar="false"
            :acciones="['editar','eliminar']"
            @editar="editSubcosto($event)"
            @eliminar="deleteSubcosto($event)">
        </datatable>

        <!-- Modal Nueva Subcosto -->
        <v-dialog
            v-model="dialog"
            width="900px"
            @click:outside = "closeModal"
        >
            <v-card>
                <v-card-title class="blue darken-1">{{ title }}</v-card-title>
                <v-card-text>
                    <form>
                        <v-row class="mx-2">
                            <v-col
                                class="align-center justify-space-between"
                                cols="4"
                            >
                                <v-select 
                                    prepend-icon="mdi-factory"
                                    v-model="subcosto.cuenta"
                                    :items="cuentas"
                                    item-text="nombre"
                                    item-value="id"
                                    label="Cuenta"
                                    color="blue darken-3"
                                    persistent-hint
                                    return-object
                                >
                                </v-select>
                            </v-col>
                            <v-col
                                class="align-center justify-space-between"
                                cols="4"
                            >
                                <v-text-field
                                    prepend-icon="mdi-barcode"
                                    placeholder="Nombre Subcosto"
                                    v-model="subcosto.nombre"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col
                                class="align-center justify-space-between"
                                cols="4"
                            >
                                <v-text-field
                                    prepend-icon="mdi-barcode"
                                    placeholder="Codigo"
                                    v-model="subcosto.codigo"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </form>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        @click="closeModal"
                    >Cerrar</v-btn>
                    <v-btn
                        color="primary"
                        @click="saveSubcosto"
                    >Grabar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- / Modal -->

        <!-- Modal upload -->
        <v-dialog
            v-model="upload"
            width="600px"
        >
            <v-card>
                <v-card-title class="green darken-1">
                    Subir listado de subcostos desde excel
                </v-card-title>
                <v-card-text>
                    <v-row class="mx-2">
                        <v-col>
                            <v-file-input v-model="file" accept=".xls, .xlsx" label="Archivo Excel"></v-file-input>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        @click="upload = false"
                    >Cancelar</v-btn>
                    <v-btn
                        color="primary"
                        @click="uploadSubcostos"
                    >Subir</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- / Modal -->
    
        <v-snackbar
            v-model="snackbar"
            :timeout="5000"
            :color="color"
        >
            {{ validation_message }}
            <template v-slot:action="{ attrs }">
                <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                    Cerrar
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>
<script>
import {mapState, mapMutations} from 'vuex'
import DataTable from '@/components/base/DataTable.vue';

export default {
    data: () => ({
        title: 'Nueva Subcosto',
        color: 'green',
        snackbar: false,
        errors: [],
        validation_message: '',
        search: null,
        file: null,
        dialog: false,
        upload: false,
        changeState: false,
        subcostoIndex: -1,
        cuentas: [],
        subcosto: {
            id: 0,
            cuenta: {},
            nombre: '',
            codigo: '',
        },
        subcostosListHeaders: [
            {
                text: 'Id',
                align: 'center',
                sortable: true,
                value: 'id',
                class: "blue-grey darken-4 white-text"
            },
            {
                text: 'Agrupación de cuentas',
                align: 'left',
                sortable: true,
                value: 'cuenta.nombre',
                class: "blue-grey darken-4 white-text"
            },
            {
                text: 'Subcosto',
                align: 'left',
                sortable: true,
                value: 'nombre',
                class: "blue-grey darken-4 white-text"
            },
            {
                text: 'Codigo',
                align: 'left',
                sortable: true,
                value: 'codigo',
                class: "blue-grey darken-4 white-text"
            },
            {
                text: 'Acciones',
                align: 'center',
                sortable: false,
                value: 'actions',
                class: "blue-grey darken-4 white-text"
            },
        ],
        subcostosListBody: [],
        botones:[
            {boton:'crear',tooltip:'Nuevo Subcosto'},
            {boton:'cargar',tooltip:'Carga Masiva'},
            {boton:'eliminar',tooltip:'Borrar Todo'}
        ]
    }),
    mounted() {
        this.loadCuentas();
        this.loadSubcostos();
    },
    methods:{
        async loadCuentas(){
            let url = `${this.base_url}cuentas`;
            await this.axios.get(url, this.headers).then((response)=>{
                this.cuentas = response.data;
                this.subcosto.cuenta = this.cuentas[0];
            }).catch((error)=>{
                console.log(error);
            });
        },
        async loadSubcostos(){
            let url = `${this.base_url}subcostos`;
            await this.axios.get(url, this.headers).then((response)=>{
                this.subcostosListBody = response.data;
            }).catch((error)=>{
                console.log(error);
            });
        },
        async saveSubcosto(){
            // validaciones
            this.errors = [];
            if(!this.subcosto.nombre){
                this.errors.push("Debe indicar nombre del subcosto");
            }else if(this.subcosto.nombre.length > 50){
                this.errors.push("Tamaño máximo 50 caracteres");
            }
            if(!this.subcosto.centro_costos){
                this.errors.push("Debe indicar centro de costos");
            }else if(this.subcosto.centro_costos.length > 2){
                this.errors.push("Tamaño máximo 2 caracteres");
            }
            if(this.errors.length > 0){
                this.validation_message = this.errors.join();
                this.showSnackBar(true);
                return;
            }
            let url = `${this.base_url}subcostos`;
            let subcostoData = {
                'nombre': this.subcosto.nombre,
                'codigo': this.subcosto.codigo,
                'mostrar': 1
            };
            if(this.subcosto.id > 0){
                url = url + "/" + this.subcosto.id;
                await this.axios.put(url, subcostoData, this.headers).then((response)=>{
                    let index = this.subcostosListBody.map(subcosto => {
                        return subcosto.id;
                    }).indexOf(this.subcosto.id);
                    this.subcostosListBody.splice(index, 1, response.data);
                    this.showSnackBar(false);
                }).catch((error)=>{
                    this.validation_message = error;
                    this.showSnackBar(true);
                });
            }else{
                await this.axios.post(url, subcostoData, this.headers).then((response)=>{
                    this.subcostosListBody.push(response.data);
                    this.showSnackBar(false);
                }).catch((error)=>{
                    this.errors = error.response.data;
                    this.validation_message = this.errors.join();
                    this.showSnackBar(true);
                });
            }
            this.cleanForm();
        },
        editSubcosto(item) {
            this.subcostoIndex = this.subcostosListBody.indexOf(item);
            this.subcosto = Object.assign({}, item);
            this.dialog = true;
            this.title = "Editar Subcosto";
        },
        async deleteSubcosto(item) {
            this.$confirm('Desea borrar esta subcosto?').then(res => {
                if(res){
                    let url = `${this.base_url}subcostos/${item.id}`;
                    this.axios.delete(url, this.headers).then((response)=>{
                        console.log(response);
                        this.loadSubcostos();
                        this.showSnackBar(false);
                    }).catch((error)=>{
                        this.validation_message = error;
                        this.showSnackBar(true);
                    });
                }
            });
        },
        showSnackBar(error){
            if(error){
                this.color = 'red';
            }else{
                this.color = 'green';
                this.validation_message = "Ejecutado exitosamente!";
            }
            this.snackbar = true;
        },
        closeModal(){
            this.cleanForm();
            this.dialog = false;
            this.error = null;
            this.title = "Nuevo Subcosto";
        },
        cleanForm(){
            this.subcosto.id = 0;
            this.subcosto.nombre = "";
            this.subcosto.empresa = null;
            this.dialog = false;
        },
        async uploadSubcostos(){
            this.headers.headers['Content-Type'] = "multipart/form-data";
            let url = `${this.base_url}uploads/subcostos`;
            let formData = new FormData();
            formData.append("excel", this.file);
            let response = null; 
            try{
                response = await this.axios.post(url, formData, this.headers);
                this.file = null;
                this.upload = false;
                this.loadSubcostos();
                this.showSnackBar(false);
            }catch(error){
                response = error.response;
                let msg = response.data.data;
                this.validation_message = response.status + ': ' + response.data.message + '. '+ msg;
                this.showSnackBar(true);
            }
        },
        async deleteAll(){
            this.$confirm('Desea borrar todos los datos?').then(res => {
                if(res){
                    let url = `${this.base_url}truncar/subcostos`;
                    this.axios.get(url, this.headers).then((response)=>{
                        console.log(response);
                        this.loadSubcostos();
                        this.showSnackBar(false);
                    }).catch((error)=>{
                        this.validation_message = error;
                        this.showSnackBar(true);
                    });
                }
            });
        },
        async ocultar(item) {
            this.$confirm('Desea ocultar esta subcosto?').then(res => {
                if(res){
                    let url = `${this.base_url}subcostos/ocultar/${item.id}`;
                    this.axios.get(url, this.headers).then((response)=>{
                        console.log(response);
                        this.showSnackBar(false);
                        this.loadSubcostos();
                    }).catch((error)=>{
                        this.validation_message = error;
                        this.showSnackBar(true);
                    });
                }
            });
        },
        async mostrar(item) {
            this.$confirm('Desea mostrar esta subcosto?').then(res => {
            if(res){
                let url = `${this.base_url}subcostos/mostrar/${item.id}`;
                this.axios.get(url, this.headers).then((response)=>{
                    console.log(response);
                    this.showSnackBar(false);
                    this.loadSubcostos();
                }).catch((error)=>{
                    this.validation_message = error;
                    this.showSnackBar(true);
                });
            }
            });
        },
        ...mapMutations(['actualizarBaseUrl', 'mostrarLoading', 'ocultarLoading']),
    },
    computed:{
        ...mapState(['loading', 'base_url', 'headers']),
    },
    components:{
        'datatable': DataTable
    }
  }
</script>